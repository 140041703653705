import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getActivities } from "../../providers"

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero2 } from "../../components/Hero2/index"
import Brochure from "../../components/Programming/Brochure"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const Activities = () => {
  const [activities, setActivities] = useState({})

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const _activities = await getActivities()
        setActivities(_activities[0])
      } catch (error) {
        console.log(error)
      }
    }
    fetchActivities()
  }, [])

  return (
    <MainLayout>
      <Container>
        <Hero2 data={activities} />
        <Brochure data={activities?.brochure_image} />
      </Container>
    </MainLayout>
  )
}

export default Activities
