import React, { useState, useEffect } from "react"
import { Table } from "antd"
import styled from "styled-components"

import { dateFormat } from "../../utils"

import { Title } from "../Title"
import { Link } from "gatsby"
import { getGeneralInfo} from "../../providers"

const columns = [
  {
    title: "Fecha de inicio",
    dataIndex: "init_date",
    render: init_date => dateFormat(init_date),
  },
  {
    title: "Nombre del Curso",
    dataIndex: "title",
  },
  {
    title: "Días",
    dataIndex: "days",
  },
  {
    title: "Horario",
    dataIndex: "schedule",
    width: "150px"
  },
  {
    title: "Modalidad",
    dataIndex: "modality",
  },
  {
    title: "Instructor",
    dataIndex: "instructor",
    render: instructor => (<a style={{color:"white" , display: "flex" , alignItems:"center"}} href={instructor.cv} target="_blank"> <div>{instructor.name}</div> <svg style={{margin:"0px"}} width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.57143 1.6875C1.15179 1.6875 0 2.82129 0 4.21875V22.7812C0 24.1787 1.15179 25.3125 2.57143 25.3125H21.4286C22.8482 25.3125 24 24.1787 24 22.7812V4.21875C24 2.82129 22.8482 1.6875 21.4286 1.6875H2.57143ZM7.86857 8.71351C8.9205 8.71351 9.85511 9.07153 10.5433 9.60847C11.1798 10.1069 11.4778 10.6055 11.7379 11.053L9.92057 11.9468C9.79055 11.6533 9.63412 11.3463 9.23239 11.0144C8.79054 10.6688 8.34964 10.567 7.97218 10.567C6.49221 10.567 5.71307 11.9219 5.71307 13.4298C5.71307 15.4111 6.73955 16.3945 7.97218 16.3945C9.16682 16.3945 9.64779 15.5766 9.95861 15.0532L11.7643 15.9606C11.426 16.484 11.1014 16.996 10.3741 17.4935C9.98507 17.7619 9.08963 18.2865 7.81682 18.2865C5.38854 18.2865 3.42857 16.5486 3.42857 13.4931C3.42857 10.8229 5.27223 8.71351 7.86857 8.71351ZM11.9123 8.99443H14.3533L16.2488 15.4111L18.1304 8.99448H20.5714L17.3904 18.018H15.0541L11.9123 8.99443Z" fill="white"/>
    </svg>
     </a>)
  },
  {
    title: "Vacantes",
    dataIndex: "vacancies",
    render: vacancies => vacancies =="Disponibles"? <svg className="circles" width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="51" cy="51" r="51" fill="white"/>
    <path d="M21 51L41.3333 71L82 31" stroke="#60BF94" stroke-width="13"/>
    </svg>:<svg className="circles" width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="51" cy="51" r="51" fill="white"/>
    <path d="M78.19 74H62.26L51.64 59.15L40.66 74H25.36L43.99 49.61L25.99 25.58H41.74L52 39.71L62.53 25.58H77.47L59.47 49.25L78.19 74Z" fill="#F2AE2C"/>
    </svg>

    
  },
]

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;

    /*.content-btn {
      width: 16%;
      text-align: center;
      img {
        overflow: hidden;
        transition: 0.2s transform ease-in-out;
      }
      img:hover {
        transform: scale(1.05);
      }
    }*/
`
const HeadContainer=styled.div`
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  display:flex;
  justify-content: center;
  margin: 30px 0 ;
`
const FirstBlock=styled.div`
  position: relative;
  width: 100%;
  background-color: #0044B0;
  display:flex;
  justify-content: center;
`
const FirstBlockChild= styled.div`
max-width: 1440px;
width: 100%;
overflow: hidden;
position: relative;
display: flex;
justify-content: center;
flex-direction: column;
background-color: #0044B0;
margin: 50px 0 50px 60px ;
.content-programing {
    margin: 40px 0 70px 0;
    width: 100%;
    display: flex;
    .content-table {
      width: 100%;
    }
    .table {
      overflow: none;
      border-left: 2px solid #FFFFFF;

      svg{
        width: 40px;
        margin-left: 25%;
      }
      .circles{
        position: relative;
      }
      .ant-table-thead th {
        background: #FFFFFF;
        color: #0044B0;
        font-weight: 700;
        border-left: 3px solid #0044B0;
        left: -3px;
      }
      .ant-table-cell {

        font-size: 18px;
        line-height: 100%;
        background: #0044B0;
        color:#FFFFFF;
        border: 2px solid #FFFFFF;
        left: -1.5px;
      }
      .ant-pagination {
        display: none;
      }
      .ant-table-tbody > tr > td, {
        position:relative }

    }
  }
  @media (max-width: 900px) {
    .content-programing {
      flex-direction: column;
      .content-table,
      .content-btn {
        width: 90%;
      }
      .content-btn {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 500px) {
    width: 100%;
   
    margin: 20px 0px 0px 30px;

    .content-programing {
      margin: 20px 0 50px 0;
    width: 100%;
    display: flex;
      
      .table {
      overflow: scroll;
      border-left: 2px solid #FFFFFF;

      .circles{
        width: 30px;
      }
      .ant-table-thead th {
        background: #FFFFFF;
        color: #0044B0;
        font-weight: 700;
        border-left: 3px solid #0044B0;
        left: -3px;
      }
      .ant-table-cell {

        font-size: 13px;
        line-height: 100%;
        background: #0044B0;
        color:#FFFFFF;
        border: 2px solid #FFFFFF;
        left: -1.5px;
      }
      .ant-pagination {
        display: none;
      }
      .ant-table-tbody > tr > td{
        padding: 2px 8px;
      }
    }
  }}
`

const Header= styled.div`
  font-weight: 700;
  font-size: 1.7em;
  display: flex;
  color: #292929;
  @media (max-width: 500px) {
    font-size: 18px
  }
`
const Header2= styled.div`
  font-weight: 700;
  font-size: 1.7em;
  display: flex;
  color: #60BF94;
  @media (max-width: 500px) {
    font-size: 18px
  }
`

const ProgrammingCourses = ({ data }) => {
  const [info, setInfo] = useState({})
 
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const _info = await getGeneralInfo()
      
        setInfo(_info)
      } catch (error) {
        console.log(error)
    
      }
    }
    fetchCourse()
  }, [])



  return (
    <Container>
      <HeadContainer><Header>"Que el éxito nos acompañe"</Header></HeadContainer>
      <FirstBlock>
      <FirstBlockChild>
      <Header2>Programación</Header2>
      <div className="content-programing">
        <div className="content-table">
          <Table className="table" dataSource={data} columns={columns} />
        </div>
      </div>
      </FirstBlockChild>
      </FirstBlock>
    </Container>
  )
}

export default ProgrammingCourses
