import * as React from "react"
import { TitleHero } from "../TitleHero"
import { Subtitle } from "../Subtitle"
import styled from "styled-components"

const Container = styled.div`
  height: 33.25rem;
  background: url(${p => p.background}) center;
  background-size: cover;
  background-position: cover;

  @media (max-width:900px){
  height: 12rem;
}

`
const ContainerInfo = styled.div`
  width: 90%;
  height: 100%;
  padding: 0 3.125em;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  div {
    width: 50%;
    .subtitle {
      margin-top: 60px;
      width: 25rem;
      color: #FFFFFF;
    }
  }
`
export const Hero2 = ({ data }) => {
  return (
    <>
      <Container background={data.background_image}>
        <ContainerInfo>
          <div>
            <TitleHero className="white">{data.title}</TitleHero>
            <Subtitle className="subtitle">{data.subtitle}</Subtitle>
          </div>
        </ContainerInfo>
      </Container>
    </>
  )
}
