import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button02 } from "../Button02"
import { Title } from "../Title"
import ProgrammingCourses from "../Programming/ProgrammingCourses.js"

const Container = styled.div`
  width: 100%;
  /*margin: 3.125rem auto 0;*/
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const FirstBlock=styled.div`
  position: relative;
  width: 100%;
  display:flex;
  justify-content: center;

`
const SecondBlock=styled.div`
  position: relative;
  width: 100%;
  
  display:flex;
  justify-content: center;
  color: #FFFFFF;
  margin-bottom: 3em;

  .img_banner{
    width: 100%;
    object-fit: cover;
  }
  @media (max-width:500px) {
    max-height: auto;
    margin-bottom: 1em;

    .img_banner{
      object-fit: contain;
      width: 100%;
    }
  }
`
const ThirdBlock=styled.div`
  position: relative;
  width: 100%;
  display:flex;
  justify-content: center;
  margin-bottom: 3em;

`
const ThirdBlockChild= styled.div`
max-width: 1440px;
width: 100%;
position: relative;
flex-direction: column;
display: flex;
justify-self: center;
font-size: 32px;
color: #0044B0;
font-weight: 700;
p,
.btn {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
@media (max-width: 500px) {
  font-size: 15px;

}
`
const FirstBlockChild= styled.div`
max-width: 1440px;
width: 100%;
position: relative;
display: flex;
flex-direction: column;
justify-self: center;
justify-content: center;
align-items: center;

.imagen,
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .imagen {
    margin-top: 20px;
    width: 100%;
    justify-content: center;

    img {
      width: 60%;
      object-fit: cover;
    }
  }

  .btn {
    margin: 49px 0 72px;
  }

.optional{
  width: 100%;
  justify-content: center;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  .green{
    color: #60BF94;
    font-size: 22px;
    font-weight: 600;
    text-align:center;
  }
  .orange{
    color: #F2AE2C;
    font-size: 22px;
    font-weight: 700;
    padding-left: 5px;
    text-align:center;

  }
  .greenlittle{
    color: #60BF94;
    font-size: 18px;
    font-weight: 500;
    padding-left: 5px;
    text-align:center;

  }
}

@media (max-width: 900px) {
  padding: 0 1em;
  .optional{
    .green,.orange{
      font-size: 18px;

    }
    .greenlittle{
      font-size: 15px;
    }
  }
}

@media (max-width: 500px) {

  .btn {
    margin: 30px 0;

  }
  .optional{
    width: 100%;
    padding: 0 15px;
    align-content: flex-end;
    text-align: center;
    display: flex;
    .green,.orange{
      font-size: 14px;
    }
    .greenlittle{
      font-size: 13px;
    }
  }
}
`
const Header= styled.div`
  font-weight: 700;
  font-size: 2em;
  color: #0044B0;
  width: 70%;


  @media (max-width: 900px) {
    font-size: 18px;
    padding-left: 15px;
  }
`
const ContentSocialNetwork = styled.div`
width: 50%;
  position: relative;
  flex-direction: row;
  display: flex;
  margin: 50px 0;
  //padding: 0 550px;
  justify-self: center;
justify-content: center;

  .link {
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: flex-start;
    //padding-left: 30px;
    margin-bottom: 30px;
    border-right: none;
  } ;
  @media (max-width: 500px) {
    padding: 0 0px;
  }

`
const ContentLink = styled(Link)`
  display: flex;
  justify-content: center;
  img{
    width: 48px;
  }
  :hover{
    border: none;
  color: #FFFFFF;
  transform: scale(1.1);
  will-change: transform;

  }
@media (max-width: 500px) {
  width: 100%;
  img{ width: 25px}
}
`

const Brochure = ({ data, background,contact, banner }) => {

  return (
    <Container>
      <FirstBlock>
      <FirstBlockChild>
      <div className="btn">
        <a href={contact?.whatsapp_link} target="_blank">
          <Button02 background={background}>Empieza hoy</Button02>
        </a>
      </div>
      <Header>Programación</Header>
      <div className="imagen">
        <img src={data} alt="Autodesk inventor para estudiantes. Curso certificado"/>
      </div>
      <div className="btn">
        <a href={data} target="_blank">
          <Button02 background={background}>Descargar</Button02>
        </a>
      </div>
        
        <div className="optional">
          <div className="green">{banner?.brochure_text&&banner?.brochure_text}</div><div className="orange">{banner?.brochure_bold_text&&banner?.brochure_bold_text}</div><div className="greenlittle">{banner?.brochure_secondary_text&&banner?.brochure_secondary_text}</div>
          </div>
        
        <ContentSocialNetwork>
              <ContentLink
                className="link"
                to={contact?.facebook_link}
                target="_blank"
              >
                <img src="/images/Contact/fb.svg" alt="facebook de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={contact?.instagram_link}
                target="_blank"
              >
                <img src="/images/Contact/ig.svg" alt="instagram de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={contact?.tiktok_link}
                target="_blank"
              >
                <img src="/images/Contact/tiktok.svg" alt="tiktok de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={contact?.linkedin_link}
                target="_blank"
              >
                <img src="/images/Contact/linkedin.svg" alt="linkedin de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={contact?.youtube_link}
                target="_blank"
              >
                <img src="/images/Contact/youtube.svg" alt="youtube de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>
            
        </ContentSocialNetwork>
            </FirstBlockChild>
        </FirstBlock>
        <SecondBlock>
                    <a href={banner?.banner_image_link} target="_blank">
                    <img className="img_banner" src={banner?.banner_image}/>
                    </a>
        </SecondBlock>
      <ThirdBlock>
        <ThirdBlockChild>
      <p>El primer paso para el éxito, está aquí</p>
      <div className="btn">
        <a href={contact?.whatsapp_link} target="_blank">
          <Button02 background={background}>Matrícula aquí</Button02>
        </a>
      </div>
      </ThirdBlockChild>
      </ThirdBlock>
    </Container>
  )
}

export default Brochure
